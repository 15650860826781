import "./App.css";
import heading_image from "./images/header.png";
import pic3 from "./images/pic3.jpeg";
import telImg from "./images/tel.png";
import pic4 from "./images/pic4.jpeg";
import pic2 from "./images/pic2.jpeg";
import pic1 from "./images/pic1.jpeg";

import mehiron from "./images/Mehiron.png";
import openjam from "./images/openjam.png";
import bekitzur from "./images/Bekizur.png";

function App() {
  return (
    <div className="main_container">
      <div className="second_container">
        <div className="img_cont_1">
          <img src={heading_image} width="100%" className="first_header" />
        </div>
        <div className="general_container">
          <div className="pic3_cont">
            <img src={pic3} width="100%" />
          </div>
          <p>
            חדר החזרות והקלטות ביט עם מוניטין של יותר מ-20 שנה, עכשיו ברח' חומה
            ומגדל 28 בתל-אביב. המקום מרווח צבעוני ומאוורר עם סאונד מצויין
            ובאווירה ביתית נהדרת. חצר ענקית להופעות. יש חניה בשפע במקום{" "}
          </p>
        </div>
        <div className="tel_container">
          <a href="tel:0523505766">
            <p className="tel">052-3505766</p>
          </a>
          <img src={telImg} width={120} />
        </div>
        <div className="black_seperator"></div>
        <div className="general_container top">
          <div className="pic_container">
            <img src={pic4} width={220} />
          </div>
          <div className="text">
            <div className="mehiron_cont">
              <img src={mehiron} width="100%" className="bottom" />
            </div>
            <p className="max_width">
              שעת חזרה - 90 ש"ח.
              <br />
              <br />
              הקלטת חזרה באיכות מעולה - תוספת 50 ש"ח לכל החזרה.
              <br />
              <br />
              שעת הקלטה מקצועית - 150 ש"ח
            </p>
          </div>
        </div>
        <div className="black_seperator"></div>
        <div className="general_container top">
          <div className="pic_container">
            <div className="yellow_seperator_small"></div>
            <div className="yellow_seperator_big"></div>
            <img src={pic2} width={320} className="image" />
          </div>
          <div className="text">
            <div className="mehiron_cont">
              <img src={openjam} width="100%" className="bottom" />
            </div>
            <p className="max_width">
              בכל יום חמישי ושישי מ-22:00 ג'אם בביט. מוזמנים לבוא ולנגן עד השעות
              המאוחרות של הלילה עם מיטב הנגנים
            </p>
          </div>
        </div>
        <div className="black_seperator"></div>
        <div className="general_container top">
          <div className="pic_container">
            <div className="yellow_seperator_small none"></div>
            <div className="yellow_seperator_big none"></div>
            <img src={pic1} width={250} className="image" />
          </div>
          <div className="text">
            <div className="mehiron_cont">
              <img src={bekitzur} width="100%" className="bottom" />
            </div>
            <p className="max_width">
              אתם מוזמנים לבוא ולנגן בכל שעה, אשר תמיד נמצא כדי לתת יד עם הבס,
              הגיטרה ובהקלטה. במקום יש מטבח, חצר ענקית ומאווררת, מתאים לג'אמולדת
              חניה בשפע ואווירה חופשית!
            </p>
          </div>
        </div>
        <div className="black_seperator"></div>
        <div className="youtube_container">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/RP_k4w0y2LU?si=5hE4NnrPWFCmvs3u"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default App;
